<template>
  <div id="badges-detail" v-loading.fullscreen.lock="loading">
    <defaultTemplate>
      <div class="pd-t-5 pd-x-5" v-if="BadgesHistory">
        <div class="is-flex js-between ai-center">
          <div class="s-width">
            <p class="font-20 font-weight-500">Badge History</p>
          </div>

          <div class="is-flex ai-center text-right">
            <el-input
              class="mg-r-7"
              v-if="Search"
              placeholder="Search"
              v-model="searchText"
              @keyup.13.native="searchSubject"
            ></el-input>
            <i
              class="font-14 font-weight-bold color-green-light el-icon-search"
              @click="Search = !Search"
            ></i>
          </div>
        </div>

        <div class="mg-t-5" v-if="BadgesHistory.length > 0">
          <div v-for="(badge, index) in BadgesHistory" :key="index">
            <router-link
              :to="`/badges-history/${badge.courseId}`"
              class="cursor-pointer"
            >
              <div class="card">
                <div class="is-flex js-between ai-center">
                  <div>
                    <p class="font-12 mg-b-0 color-35 name-sub">
                      {{ badge.courseName }} ({{ badge.course }})
                    </p>
                    <p
                      class="mg-0 mg-t-7 font-10 color-purple"
                      v-if="badge.badges.length > 1"
                    >
                      {{ badge.badges.length + " Badges" }}
                    </p>
                    <p class="mg-0 mg-t-7 font-10 color-purple" v-else>
                      {{ badge.badges.length + " Badge" }}
                    </p>
                  </div>
                  <p class="font-12 mg-b-6 color-purple">
                    <i class="fas fa-chevron-right"></i>
                  </p>
                </div>
              </div>
            </router-link>
          </div>
        </div>

        <div v-else class="text-center mg-t-2">
          <p class="font-22 font-weight-500">ไม่พบข้อมูล Badge History</p>
        </div>
      </div>
    </defaultTemplate>
  </div>
</template>

<script>
import { HTTP } from "@/service/axios";
import defaultTemplate from "@/template/default.vue";
export default {
  components: {
    defaultTemplate
  },
  mounted() {
    this.getBadgesHistory();
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },

  data() {
    return {
      searchText: "",
      Search: false,
      BadgesHistory: null,
      BadgesHistoryConst: null,
      loading: false
    };
  },
  methods: {
    getBadgesHistory() {
      this.loading = true;
      console.log("getBadgesHistory");
      HTTP.defaults.headers.common.Authorization =
        `Bearer ` + this.user.accessToken;
      HTTP.get(`badges/history`)

        .then(res => {
          console.log(res);
          if (res.data.success) {
            this.BadgesHistory = res.data.obj;
            this.BadgesHistoryConst = res.data.obj;
          }
        })
        .catch(e => {
          console.log(e);
          this.alertCatchError(e.response.status);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    searchSubject() {
      console.log(this.searchText);
      if (this.searchText != "") {
        this.BadgesHistory = this.BadgesHistoryConst.filter(
          fil =>
            fil.course.toLowerCase().indexOf(this.searchText.toLowerCase()) >
              -1 ||
            fil.courseName
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1
        );
      } else {
        this.getBadgesHistory();
      }
    }
  }
};
</script>

<style></style>
